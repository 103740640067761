export const submitEmail = (email: string) => {
  // do not spam Zapier while testing
  if (process.env.NODE_ENV === "development") {
    // return;
  }
  fetch("https://hooks.zapier.com/hooks/catch/7672163/bcnm7rb/", {
    method: "POST",
    body: JSON.stringify({ email: email })
  })
    .then((response: Response) => console.log(response))
    .catch((e) => console.log(e));
};

export const validateEmail = (email: string) =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(email);
