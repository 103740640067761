import './Home.scss';

import Video from '../../Assets/Media/Earth.webm';
import Logo from '../../Assets/Media/logo-primary-white.png';
import { EmailSignup } from '../Components/EmailSignup';

export const Home = () => {
  return (
    <div className="home">
      <video className="home-video" autoPlay loop muted>
        <source src={Video} type="video/webm" />
      </video>
      <section className="home-content">
        <img src={Logo} alt="Helios Logo White" className="home-logo" />
        <h1>Stay Tuned...</h1>
        <h4 className="home-h4">
          Over the next 10 years, Americans will spend nearly $2 TRILLION on
          residential electricity.
        </h4>
        <h4 className="home-h4">
          Heliose was born to accelerate America's energy transition and save
          consumers as much as 33%. (That’s a really big number)
        </h4>
        <EmailSignup />
      </section>
    </div>
  );
};

export default Home;
