import './EmailSignup.scss';

import React from 'react';

import { submitEmail, validateEmail } from '../../Utils/Functions';

export class EmailSignup extends React.Component {
  state: {
    email: string;
    submitted: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      submitted: false
    };
  }

  render() {
    return (
      <div
        className={`signup ${this.state.submitted ? "signup-submitted" : ""}`}
      >
        <input
          className="signup-input"
          type="email"
          disabled={this.state.submitted}
          placeholder="Email Address"
          value={this.state.email}
          onChange={(e) => this.setState({ email: e.target.value })}
          autoComplete="on"
          onKeyUp={(e) => {
            if (e.key === "Enter" && validateEmail(this.state.email)) {
              submitEmail(this.state.email);
              this.setState({ submitted: true });
            }
          }}
        />
        <div
          className={`signup-submit ${
            this.state.submitted
              ? "signup-submit-submitted"
              : validateEmail(this.state.email)
              ? "signup-submit-valid"
              : "signup-submit-invalid"
          }`}
          onClick={() => {
            if (validateEmail(this.state.email)) {
              submitEmail(this.state.email);
              this.setState({ submitted: true });
            }
          }}
        >
          <h6>
            {!this.state.submitted
              ? "Keep Me Updated"
              : "Thanks! We'll be in touch."}
          </h6>
        </div>
      </div>
    );
  }
}
